body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  background-color: #2b2b25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Fonts */
@font-face {
  font-family: Dis-Black;
  src: url(./assets/Fonts/PlayfairDisplay-Black.ttf);
  font-display: swap;
}
@font-face {
  font-family: Dis-Black-Italic;
  src: url(./assets/Fonts/PlayfairDisplay-BlackItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Dis-Bold;
  src: url(./assets/Fonts/PlayfairDisplay-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Dis-Italic;
  src: url(./assets/Fonts/PlayfairDisplay-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: Dis-Regular;
  src: url(./assets/Fonts/PlayfairDisplay-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Rel-Bold;
  src: url(./assets/Fonts/Raleway-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Rel-Extra-Bold;
  src: url(./assets/Fonts/Raleway-ExtraBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Rel-Medium;
  src: url(./assets/Fonts/Raleway-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: Rel-Regular;
  src: url(./assets/Fonts/Raleway-Regular.ttf);
  font-display: swap;
}
iframe{
  width: 100% !important;
  height: 100% !important;
}
/* Fonts */
.react-carousel-custom{
  margin: 0 auto;
}
.react-multi-carousel-dot {
  display: flex;
  align-items: center;
}
.react-multi-carousel-dot button {
  width: 14px !important;
  height: 14px !important;
  border-width: 0px !important;
  background: rgb(91, 68, 25) !important;
}
.react-multi-carousel-dot--active button {
  background: transparent !important;
  border-color: rgb(91, 68, 25) !important;
  border-width: 2px !important;
  padding: 2px !important;
  width: 16px !important;
  height: 16px !important;
}

.slick-slider.slick-initialized{
  padding-left: 50px ;
  padding-right: 50px ;
/* overflow-x: hidden; */
}
.slick-dots{
  position: inherit !important;
  /* width: 399px; */
}

.slick-dots li button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.slick-dots li button:before{
  display: none;
}
.slick-dots li button{
  border-radius: 50%;;
  width: 14px !important;
  height: 14px !important;
  border-width: 0px !important;
  background: #d59570 !important;
}
.slick-dots li.slick-active button{
  background: transparent !important;
  border-color: #d59570 !important;
  border-width: 2px !important;
  padding: 2px !important;
  width: 16px !important;
  height: 16px !important;
}


/* backdrop video */
.MuiDialog-root .MuiBackdrop-root{
  background-color: rgba(0, 0, 0, 0.9);
}




@media(max-width:576px){
  .slick-dots{
  bottom: -5px;
  }
}
@media(min-width:576px){
  .slick-slider.slick-initialized{
    padding-left: 100px ;
    padding-right: 100px ;
  }
}